import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { PayInternalInvoiceCacheKeys, InvoiceID } from '../../../invoicesTypes';

import { usePayInternalInvoiceForm } from '../../forms/PayInternalInvoiceForm/hooks/usePayInternalInvoiceForm';

import { PayInternalInvoiceForm } from '../../forms/PayInternalInvoiceForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { invoicesKeys, words } from '../../../../../locales/keys';
import { useMemo } from 'react';

const PAY_INTERNAL_INVOICE_FORM = 'pay-internal-invoice-form';

interface PayInternalInvoiceModalButtonProps {
  cacheKeys?: PayInternalInvoiceCacheKeys;
  className?: ClassName;
  disabled?: IsDisabled;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  tooltipPlacement?: TooltipPlacement;
  tooltipI18nText?: I18nText;
  tooltipSingleton?: boolean;
}

interface PayInternalInvoiceModalButtonWithInvoiceIds {
  invoiceIds: InvoiceID[];
  invoiceId?: never;
}

interface PayInternalInvoiceModalButtonWithInvoiceId {
  invoiceIds?: never;
  invoiceId: InvoiceID;
}

function PayInternalInvoiceModalButton({
  invoiceId,
  invoiceIds,
  cacheKeys,
  className,
  disabled,
  i18nText,
  i18nTextClassName,
  icon,
  iconClassName,
  tooltipPlacement,
  tooltipI18nText,
  tooltipSingleton
}: PayInternalInvoiceModalButtonProps &
  (
    | PayInternalInvoiceModalButtonWithInvoiceIds
    | PayInternalInvoiceModalButtonWithInvoiceId
  )) {
  const invoiceIdsInArray = useMemo(
    () => invoiceIds || [invoiceId],
    [invoiceId, invoiceIds]
  );

  const {
    control,
    payInternalInvoiceLoading,
    payInternalInvoiceErrorMessage,
    resetPayInternalInvoiceForm,
    handlePayInternalInvoice
  } = usePayInternalInvoiceForm({
    invoiceIds: invoiceIdsInArray,
    cacheKeys
  });

  return (
    <FormModalButton
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      modalSize="md"
      disabled={disabled}
      form={PAY_INTERNAL_INVOICE_FORM}
      i18nSubmitText={words.pay}
      icon={icon}
      iconClassName={iconClassName}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      tooltipSingleton={tooltipSingleton}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      i18nTitle={invoicesKeys.payFromAv}
      isLoading={payInternalInvoiceLoading}
      onOpen={resetPayInternalInvoiceForm}
      onSubmit={handlePayInternalInvoice}
    >
      <PayInternalInvoiceForm
        form={PAY_INTERNAL_INVOICE_FORM}
        control={control}
      />

      <div className="px-4">
        <AlertMessage message={payInternalInvoiceErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default PayInternalInvoiceModalButton;
