import { useCallback } from 'react';
import * as yup from 'yup';

import {
  SmartContractRuleContractorCompetencyID,
  SmartContractRuleFields,
  SmartContractRuleID,
  UpdateSmartContractsCacheKeys
} from '../../../../../smartContractsTypes';
import { SmartContractCheckInFormData } from '../../SmartContractCheckInForm.types';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCheckInToSmartContractRule } from '../../../../../hooks/useCheckInToSmartContractRule';

import { formsErrors } from '../../../../../../../locales/keys';

const smartContractCheckInFormSchema = yup.object({
  [SmartContractRuleFields.CONTRACTOR_COMPETENCY_ID]: yup
    .number()
    .required(formsErrors.required)
});

interface SmartContractCheckInFormOptions {
  smartContractRuleId: SmartContractRuleID;
  contractorCompetencyId?: SmartContractRuleContractorCompetencyID;
  cacheKeys?: UpdateSmartContractsCacheKeys;
  afterCheckIn?: () => void;
}

function useSmartContractCheckInForm({
  contractorCompetencyId,
  smartContractRuleId,
  cacheKeys,
  afterCheckIn
}: SmartContractCheckInFormOptions) {
  const { handleSubmitReactHookForm, control, resetForm } =
    useReactHookForm<SmartContractCheckInFormData>({
      defaultValues: contractorCompetencyId ? { contractorCompetencyId } : {},
      isModalForm: true,
      schema: smartContractCheckInFormSchema
    });

  const {
    checkInToSmartContractRule,
    checkInToSmartContractRuleErrorMessage,
    checkInToSmartContractRuleLoading,
    checkInToSmartContractRuleReset
  } = useCheckInToSmartContractRule({ cacheKeys });

  const handleInitSmartContractCheckInForm = useCallback<() => void>(() => {
    resetForm({ contractorCompetencyId });
  }, [resetForm, contractorCompetencyId]);

  return {
    control,
    checkInToSmartContractRule,
    checkInToSmartContractRuleLoading,
    checkInToSmartContractRuleErrorMessage,
    checkInToSmartContractRuleReset,
    handleInitSmartContractCheckInForm,
    handleSubmit: handleSubmitReactHookForm({
      onSubmit: async (data: SmartContractCheckInFormData) =>
        checkInToSmartContractRule({
          uuid: smartContractRuleId,
          contractorCompetencyId: data.contractorCompetencyId
        }).then(() => {
          afterCheckIn?.();
        }),
      dirtyFieldsOnly: false
    })
  };
}

export default useSmartContractCheckInForm;
