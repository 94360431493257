import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface RevisionTaskQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const REVISION_TASK_QUERY = gql`
  mutation RevisionTask(
    $uuid: ID!
    $implementationDate: DateTime!
    $feedback: String
  ) {
    revisionTask(
      input: {
        uuid: $uuid
        implementationDate: $implementationDate
        feedback: $feedback
      }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
