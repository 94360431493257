import { gql } from 'graphql-request';

export const CREATE_SMART_CONTRACT_SHARE_QUERY = gql`
  mutation CreateSmartContractShare(
    $iteration: Integer!
    $share: Float!
    $shareType: String!
    $smartContractId: ID!
    $generalLedgerId: ID!
    $finalAt: DateTime
    $status: String!
  ) {
    createSmartContractShare(
      input: {
        iteration: $iteration
        share: $share
        shareType: $shareType
        smartContractId: $smartContractId
        generalLedgerId: $generalLedgerId
        finalAt: $finalAt
        status: $status
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
