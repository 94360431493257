import React, { useCallback, useMemo } from 'react';
import find from 'lodash/find';
import head from 'lodash/head';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

import { UserNanoID } from '../../../../users/usersTypes';
import {
  SmartContractRuleFields,
  SmartContractRuleID
} from '../../../smartContractsTypes';
import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import {
  UserCompetencyCompetencyName,
  UserCompetencyDefaultAt,
  UserCompetencyID,
  UserCompetencyLabel,
  UserCompetencyValue
} from '../../../../userCompetencies/userCompetenciesTypes';
import { UpdateItemsCacheKeys } from '../../../../../types/updateItemsTypes';
import { MultiSelectDataType } from '../../../../../helpers/MultiSelect/types';

import {
  FETCH_USER_COMPETENCIES_QUERY,
  FetchUserCompetenciesQueryResponse
} from '../../../../userCompetencies/queries/fetchUserCompetencies';

import { useSmartContractCheckInForm } from '../../forms/SmartContractCheckInForm/hooks/useSmartContractCheckInForm';
import { useFinPaginatedUserCompetencies } from '../../../../userCompetencies/hooks/useFinPaginatedUserCompetencies';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { SmartContractCheckInModalAlertMessage } from './components/SmartContractCheckInModalAlertMessage';

import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';
import { Form } from '../../../../../helpers/Form';
import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { ProfileMultiSelectField } from '../../../../teams/helpers/ProfileMultiSelectField';

import { UserCompetencyCache } from '../../../../userCompetencies/UserCompetencyCache';
import { IconsEnum } from '../../../../../assets/icons/types';
import { tasksKeys, words } from '../../../../../locales/keys';

const SMART_CONTRACT_CHECK_IN_FORM = 'check-in-form';

interface SmartContractCheckInModalButtonProps {
  smartContractRuleId: SmartContractRuleID;
  userNanoId: UserNanoID;
  cacheKeys?: UpdateItemsCacheKeys;
  className?: ClassName;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  tooltipI18nText?: I18nText;
  tooltipSingleton?: boolean;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  disabled?: IsDisabled;
  excludedCompetencies?: UserCompetencyID[];
  afterCheckIn?: () => void;
}

interface UserCompetencyToSelect {
  id: UserCompetencyID;
  label: UserCompetencyLabel;
  value: UserCompetencyValue;
  defaultAt: UserCompetencyDefaultAt;
  competency: {
    name: UserCompetencyCompetencyName;
  };
}

function SmartContractCheckInModalButton({
  cacheKeys,
  userNanoId,
  smartContractRuleId,
  className,
  i18nText,
  i18nTextClassName,
  icon,
  iconClassName,
  tooltipSingleton,
  tooltipI18nText,
  disabled,
  excludedCompetencies,
  afterCheckIn
}: SmartContractCheckInModalButtonProps) {
  const { userCompetencies } =
    useFinPaginatedUserCompetencies<FetchUserCompetenciesQueryResponse>({
      query: FETCH_USER_COMPETENCIES_QUERY,
      cacheKey: UserCompetencyCache.userCompetencies(userNanoId),
      initialFilters: { userNanoId: { eq: userNanoId } }
    });

  const userCompetenciesToSelect = useMemo<UserCompetencyToSelect[]>(() => {
    if (isEmpty(excludedCompetencies)) {
      return userCompetencies;
    }

    return filter(
      userCompetencies,
      (userCompetency) => !excludedCompetencies.includes(userCompetency.id)
    );
  }, [userCompetencies, excludedCompetencies]);

  const userCompetenciesSelectData = useMemo<MultiSelectDataType[]>(
    () =>
      userCompetenciesToSelect?.map((userCompetency) => ({
        value: userCompetency.id,
        label: `${userCompetency.value ?? 0}% - ${
          userCompetency.label ?? userCompetency.competency.name
        }`
      })),
    [userCompetenciesToSelect]
  );

  const contractorCompetencyId = useMemo<UserCompetencyID>(
    () =>
      find(userCompetenciesToSelect, 'defaultAt')?.id ??
      head(userCompetenciesToSelect)?.id,
    [userCompetenciesToSelect]
  );

  const {
    checkInToSmartContractRuleLoading,
    control,
    handleSubmit,
    checkInToSmartContractRuleErrorMessage,
    checkInToSmartContractRuleReset,
    handleInitSmartContractCheckInForm,
    checkInToSmartContractRule
  } = useSmartContractCheckInForm({
    cacheKeys,
    contractorCompetencyId,
    smartContractRuleId,
    afterCheckIn
  });

  useShowToastOnErrorChange({
    error:
      size(userCompetenciesToSelect) === 1 &&
      checkInToSmartContractRuleErrorMessage
  });

  const handleCheckInToSmartContractRule = useCallback<() => Promise<unknown>>(
    () =>
      checkInToSmartContractRule({
        contractorCompetencyId,
        uuid: smartContractRuleId
      }).then(() => {
        afterCheckIn?.();
      }),
    [
      afterCheckIn,
      checkInToSmartContractRule,
      contractorCompetencyId,
      smartContractRuleId
    ]
  );

  if (size(userCompetencies) > 0 && size(userCompetenciesToSelect) === 0) {
    return null;
  }

  return size(userCompetenciesToSelect) === 1 ? (
    <PureTooltipIconButtonHelper
      className={className}
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      tooltipSingleton={tooltipSingleton}
      tooltipI18nText={tooltipI18nText}
      disabled={
        checkInToSmartContractRuleLoading ||
        isEmpty(userCompetenciesToSelect) ||
        disabled
      }
      onClick={handleCheckInToSmartContractRule}
    />
  ) : (
    <FormModalButton
      className={className}
      form={SMART_CONTRACT_CHECK_IN_FORM}
      i18nTitle={tasksKeys.buttons.checkIn}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      i18nSubmitText={words.confirm}
      tooltipSingleton={tooltipSingleton}
      tooltipI18nText={tooltipI18nText}
      icon={icon}
      iconClassName={iconClassName}
      onOpen={handleInitSmartContractCheckInForm}
      isLoading={checkInToSmartContractRuleLoading}
      onClose={checkInToSmartContractRuleReset}
      onSubmit={handleSubmit}
      disabled={disabled}
    >
      <div className="flex-1 overflow-y-auto px-2 z-0">
        <Form id={SMART_CONTRACT_CHECK_IN_FORM} className="p-4 space-y-2">
          <ProfileMultiSelectField
            classNamePrefix="av"
            control={control}
            data={userCompetenciesSelectData}
            disabled={checkInToSmartContractRuleLoading}
            i18nLabel={tasksKeys.selectShareToCheckInWith}
            inputWrapperClassName="w-full"
            isSearchable
            menuPosition="fixed"
            multi={false}
            name={SmartContractRuleFields.CONTRACTOR_COMPETENCY_ID}
            error={checkInToSmartContractRuleErrorMessage}
          />

          {isEmpty(userCompetenciesToSelect) && (
            <SmartContractCheckInModalAlertMessage userNanoId={userNanoId} />
          )}
        </Form>
      </div>
    </FormModalButton>
  );
}

export default SmartContractCheckInModalButton;
