import {
  FetchUserCompetenciesSort,
  FetchUserCompetenciesPage,
  FetchUserCompetenciesLimit,
  FetchUserCompetenciesSortTypes,
  FetchUserCompetenciesCacheKey,
  FetchUserCompetenciesGqlQuery,
  FetchUserCompetenciesFilters
} from '../../userCompetenciesTypes';

import { useFinIndexQuery } from '../../../common/hooks/base/reactQuery/useFinIndexQuery';

export const INITIAL_USER_COMPETENCIES_FILTERS = {};
export const INITIAL_USER_COMPETENCIES_PAGE = 1;
export const INITIAL_USER_COMPETENCIES_SORT = [
  FetchUserCompetenciesSortTypes.CREATED_AT_DESC
];
export const INITIAL_USER_COMPETENCIES_LIMIT = 100;

interface UserCompetenciesOptions {
  query: FetchUserCompetenciesGqlQuery;
  cacheKey: FetchUserCompetenciesCacheKey;
  initialFilters?: FetchUserCompetenciesFilters;
  initialSort?: FetchUserCompetenciesSort;
  initialPage?: FetchUserCompetenciesPage;
  initialLimit?: FetchUserCompetenciesLimit;
  options?: {
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    withoutPrefetch?: boolean;
  };
}

const scope = 'userCompetencies';

function useFinPaginatedUserCompetencies<ItemType>({
  query,
  cacheKey,
  initialFilters = INITIAL_USER_COMPETENCIES_FILTERS,
  initialSort = INITIAL_USER_COMPETENCIES_SORT,
  initialPage = INITIAL_USER_COMPETENCIES_PAGE,
  initialLimit = INITIAL_USER_COMPETENCIES_LIMIT,
  options
}: UserCompetenciesOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    isFetched,
    isLoading,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    paginateItems,
    limitItems
  } = useFinIndexQuery<ItemType>({
    scope,
    query,
    cacheKey,
    initialFilters,
    initialLimit,
    initialPage,
    initialSort,
    options
  });

  return {
    userCompetenciesData: data,
    userCompetencies: items,
    userCompetenciesError: itemsError,
    userCompetenciesErrorMessage: itemsErrorMessage,
    userCompetenciesTotalCount: itemsTotalCount,
    userCompetenciesFetched: isFetched,
    userCompetenciesLoading: isLoading,
    userCompetenciesIsPlaceholderData: isPlaceholderData,
    userCompetenciesFilters: currentFilters,
    userCompetenciesSort: currentSort,
    userCompetenciesPage: currentPage,
    userCompetenciesLimit: currentLimit,
    updateUserCompetencyCache: updateItemCache,
    filterUserCompetencies: filterItems,
    changeUserCompetenciesFilters: changeItemsFilters,
    clearUserCompetenciesFilters: clearItemsFilters,
    sortUserCompetencies: sortItems,
    paginateUserCompetencies: paginateItems,
    limitUserCompetencies: limitItems,
    prefetchUserCompetencies: prefetchItems
  };
}

export default useFinPaginatedUserCompetencies;
