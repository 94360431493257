import * as yup from 'yup';
import { addDays } from 'date-fns';

import { TaskNanoID, TaskRevisionFields } from '../../../../../tasksTypes';
import { RevisionTaskFormData } from '../../RevisionTaskForm.types';

import {
  REVISION_TASK_QUERY,
  RevisionTaskQueryResponse
} from '../../../../../queries/revisionTask.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useRevisionTask } from '../../../../../hooks/useRevisionTask';

import { dateFnsConvert } from '../../../../../../../utils/dateFnsConvert';
import { getDueDate } from '../../../../../../../utils/getDueDate';

import { formsErrors } from '../../../../../../../locales/keys';

const updateImplementationDate = getDueDate(addDays(new Date(), 1));

const revisionTaskFormData: RevisionTaskFormData = {
  implementationDate: updateImplementationDate,
  feedback: ''
};

interface RevisionTaskFormOptions {
  taskNanoId: TaskNanoID;
}

const revisionTaskValidationSchema = yup.object({
  [TaskRevisionFields.IMPLEMENTATION_DATE]: yup
    .string()
    .nullable(true)
    .required(formsErrors.required)
});

function useRevisionTaskForm({ taskNanoId }: RevisionTaskFormOptions) {
  const { handleSubmitReactHookForm, register, errors, control, resetForm } =
    useReactHookForm<RevisionTaskFormData>({
      defaultValues: revisionTaskFormData,
      isModalForm: true,
      schema: revisionTaskValidationSchema
    });

  const {
    revisionTask,
    revisionTaskLoading,
    revisionTaskErrorMessage,
    revisionTaskReset
  } = useRevisionTask<RevisionTaskQueryResponse>({
    query: REVISION_TASK_QUERY
  });

  return {
    control,
    revisionTask,
    revisionTaskLoading,
    revisionTaskErrorMessage,
    revisionTaskReset,
    resetForm,
    handleRevisionTask: handleSubmitReactHookForm({
      dirtyFieldsOnly: false,
      onSubmit: async (data: RevisionTaskFormData) =>
        revisionTask({
          uuid: taskNanoId,
          ...data,
          implementationDate: dateFnsConvert.toDateTimeWithTimezone(
            data.implementationDate
          )
        })
    }),
    registerImplementationDate: register(
      TaskRevisionFields.IMPLEMENTATION_DATE
    ),
    registerFeedback: register(TaskRevisionFields.FEEDBACK),
    validationErrors: {
      implementationDateValidationError: errors?.implementationDate?.message
    }
  };
}

export default useRevisionTaskForm;
