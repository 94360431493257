import { memo } from 'react';

import { TaskMark, TaskSupportMark } from '../../tasksTypes';

import { CheckPermissions } from '../../../../helpers/CheckPermissions';
import { Translate } from '../../../../helpers/Translate';
import { Stars } from '../../../../helpers/Stars';

import { tasksKeys, words } from '../../../../locales/keys';

import { TasksPermissions } from '../../tasksConstants';

interface TaskResultsMarksProps {
  supportMark?: TaskSupportMark;
  mark?: TaskMark;
}

function TaskResultsMarks({ supportMark, mark }: TaskResultsMarksProps) {
  if (!supportMark && !mark) {
    return null;
  }

  return (
    <div>
      {supportMark ? (
        <CheckPermissions action={TasksPermissions.READ_TASK_SUPPORT_MARK}>
          <div className="flex gap-2">
            <div className="flex-1 text-right">
              <Translate id={tasksKeys.states.qa} />
            </div>
            <div className="flex-1 text-left">
              <Stars amount={5} filledAmount={supportMark} />
            </div>
          </div>
        </CheckPermissions>
      ) : null}
      {mark ? (
        <CheckPermissions action={TasksPermissions.READ_TASK_MARK}>
          <div className="flex gap-2">
            <div className="flex-1 text-right">
              <Translate id={words.client} />
            </div>
            <div className="flex-1 text-left">
              <Stars amount={5} filledAmount={mark} />
            </div>
          </div>
        </CheckPermissions>
      ) : null}
    </div>
  );
}

export default memo<TaskResultsMarksProps>(TaskResultsMarks);
