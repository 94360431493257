import {
  TaskGqlError,
  TaskImplementationDate,
  TaskNanoID,
  TaskUUID,
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface RevisionTaskOptions {
  query: UpdateTaskGqlQuery;
}

export interface RevisionTaskResponse<RevisionTaskRecordType> {
  revisionTask: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    recordNanoId: TaskNanoID;
    record: RevisionTaskRecordType;
    errors: RevisionTaskError;
  };
}

export interface RevisionTaskInput {
  uuid: TaskUUID | TaskNanoID;
  implementationDate: TaskImplementationDate;
  feedback: string | null;
}

export interface RevisionTaskError {
  fullMessages: TaskGqlError;
}

const action = 'revisionTask';

const scope = 'task';

const pluralScope = 'tasks';

function useRevisionTask<RevisionTaskRecordType>({
  query
}: RevisionTaskOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    RevisionTaskInput,
    RevisionTaskResponse<RevisionTaskRecordType>,
    RevisionTaskError,
    RevisionTaskRecordType
  >({ action, pluralScope, scope, query });

  return {
    revisionTaskData: updateQueryData,
    revisionTaskError: updateQueryError,
    revisionTaskLoading: updateQueryLoading,
    revisionTaskErrorMessage: updateQueryErrorMessage,
    revisionTask: updateQuery,
    revisionTaskReset: updateQueryReset
  };
}

export default useRevisionTask;
