import {
  FetchSmartContractCacheKey,
  FetchSmartContractGqlQuery,
  SmartContractNanoID,
  SmartContractUUID
} from '../../smartContractsTypes';

import { useFinShowQuery } from '../../../common/hooks/base/reactQuery/useFinShowQuery';

interface SmartContractResponse<ItemType> {
  smartContract: ItemType;
}

interface SmartContractOptions {
  cacheKey: FetchSmartContractCacheKey;
  query: FetchSmartContractGqlQuery;
  uuid: SmartContractUUID | SmartContractNanoID;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
    keepPreviousData?: boolean;
    staleTime?: number;
  };
}

interface useFinSmartContractItem {
  uuid: SmartContractUUID;
}

const itemKey = 'smartContract';

function useFinSmartContract<ItemType extends useFinSmartContractItem>({
  cacheKey,
  query,
  uuid,
  options = {}
}: SmartContractOptions) {
  const {
    item,
    itemErrorData,
    itemError,
    itemLoading,
    itemFetched,
    itemFetching,
    itemIsPlaceholderData
  } = useFinShowQuery<SmartContractResponse<ItemType>, ItemType>({
    cacheKey,
    itemKey,
    query,
    uuid,
    options,
    placeholderData: null
  });

  return {
    smartContract: item,
    smartContractErrorData: itemErrorData,
    smartContractError: itemError,
    smartContractLoading: itemLoading,
    smartContractFetched: itemFetched,
    smartContractFetching: itemFetching,
    smartContractIsPlaceholderData: itemIsPlaceholderData
  };
}

export default useFinSmartContract;
