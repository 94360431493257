import {
  FetchFinAccountsFilters,
  FetchAccountsSort,
  FetchAccountsPage,
  FetchAccountsLimit
} from '../../accountsTypes';

import { InfiniteIndexQueryBaseNodeType } from '../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';
import { useFinInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useFinInfiniteIndexQuery';

import {
  INITIAL_ACCOUNTS_FILTERS,
  INITIAL_ACCOUNTS_LIMIT,
  INITIAL_ACCOUNTS_SORT
} from '../../accountsConstants';

interface AccountsOptions {
  cacheKey: string;
  initialFilters?: FetchFinAccountsFilters;
  initialLimit?: FetchAccountsLimit;
  initialPage?: FetchAccountsPage;
  initialSort?: FetchAccountsSort;
  options?: {
    cacheTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
  query: string;
}

const scope = 'accounts';

function useFinAccounts<ItemType extends InfiniteIndexQueryBaseNodeType>({
  cacheKey,
  query,
  initialFilters = INITIAL_ACCOUNTS_FILTERS,
  initialSort = INITIAL_ACCOUNTS_SORT,
  initialLimit = INITIAL_ACCOUNTS_LIMIT,
  options = {}
}: AccountsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsTotalCount,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    loadMoreItems,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    limitItems
  } = useFinInfiniteIndexQuery<ItemType>({
    cacheKey,
    initialFilters,
    initialLimit,
    initialSort,
    options,
    query,
    scope
  });

  return {
    accountsData: data,
    accounts: items,
    accountsError: itemsError,
    accountsTotalCount: itemsTotalCount,
    accountsFetched: isFetched,
    accountsLoading: isLoading,
    accountsFetchingNextPage: isFetchingNextPage,
    accountsIsPlaceholderData: isPlaceholderData,
    accountsFilters: currentFilters,
    accountsSort: currentSort,
    accountsPage: currentPage,
    accountsLimit: currentLimit,
    hasNextAccountsPage: hasNextPage,
    updateAccountCache: updateItemCache,
    loadMoreAccounts: loadMoreItems,
    filterAccounts: filterItems,
    changeAccountsFilters: changeItemsFilters,
    clearAccountsFilters: clearItemsFilters,
    sortAccounts: sortItems,
    limitAccounts: limitItems
  };
}

export default useFinAccounts;
