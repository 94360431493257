import {
  CheckInToSmartContractRuleGqlError,
  CheckInToSmartContractRuleGqlErrors,
  CheckInToSmartContractRuleGqlStatus,
  SmartContractRuleContractorCompetencyID,
  SmartContractRuleID,
  UpdateSmartContractsCacheKeys
} from '../../smartContractsTypes';

import { CHECK_IN_TO_SMART_CONTRACT_RULE_QUERY } from '../../queries/checkInToSmartContractRule.query';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface CheckInToSmartContractRuleInput {
  uuid: SmartContractRuleID;
  contractorCompetencyId: SmartContractRuleContractorCompetencyID;
}

interface CheckInToSmartContractRuleResponseErrors {
  fullMessages: CheckInToSmartContractRuleGqlErrors;
}

export interface CheckInToSmartContractRuleResponse {
  checkInToSmartContractRule: {
    status: CheckInToSmartContractRuleGqlStatus;
    errors: CheckInToSmartContractRuleResponseErrors[];
  };
}

interface CheckInToSmartContractRuleError {
  fullMessages: CheckInToSmartContractRuleGqlError;
}

interface CheckInToSmartContractRuleOptions {
  cacheKeys?: UpdateSmartContractsCacheKeys;
}

const action = 'checkInToSmartContractRule';

function useCheckInToSmartContractRule<CheckInToSmartContractRuleRecordType>({
  cacheKeys
}: CheckInToSmartContractRuleOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    CheckInToSmartContractRuleInput,
    CheckInToSmartContractRuleResponse,
    CheckInToSmartContractRuleError,
    CheckInToSmartContractRuleRecordType
  >({
    action,
    cacheKeys,
    query: CHECK_IN_TO_SMART_CONTRACT_RULE_QUERY
  });

  return {
    checkInToSmartContractRule: updateQuery,
    checkInToSmartContractRuleErrorMessage: updateQueryErrorMessage,
    checkInToSmartContractRuleError: updateQueryError,
    checkInToSmartContractRuleLoading: updateQueryLoading,
    checkInToSmartContractRuleReset: updateQueryReset,
    checkInToSmartContractRuleData: updateQueryData
  };
}

export default useCheckInToSmartContractRule;
