import React, { useCallback, useMemo } from 'react';
import { Control, FieldPath } from 'react-hook-form';
import size from 'lodash/size';

import { ErrorMessage, I18nText, IsDisabled } from '../../../../types';

import {
  MultiSelectExtendedDataType,
  MultiSelectInputChangeCallbackType
} from '../../../../helpers/MultiSelect/types';
import { TeamNanoID } from '../../../teams/teamsTypes';
import { FetchAccountsSortTypes } from '../../accountsTypes';

import { useFinAccounts } from '../../hooks/useFinAccounts';
import {
  FETCH_ACCOUNTS_QUERY,
  FetchAccountsQueryResponse
} from '../../queries/fetchAccounts.query';

import { AccountMultiSelectField } from '../AccountMultiSelectField';

import { AccountCache } from '../../AccountCache';

interface CompaniesSelectFieldProps<T> {
  disabled?: IsDisabled;
  errorMessage?: ErrorMessage;
  name: FieldPath<T>;
  control: Control<T>;
  i18nLabel?: I18nText;
  companyNanoId: TeamNanoID;
  accountsTypeChartNumbers?: string[];
}

function AccountSelectField<T>({
  control,
  disabled,
  errorMessage,
  name,
  i18nLabel,
  companyNanoId,
  accountsTypeChartNumbers = []
}: CompaniesSelectFieldProps<T>) {
  const {
    accounts,
    accountsLoading,
    accountsFetchingNextPage,
    loadMoreAccounts,
    changeAccountsFilters
  } = useFinAccounts<FetchAccountsQueryResponse>({
    cacheKey: AccountCache.companyAccountsSelectCacheKey(companyNanoId),
    query: FETCH_ACCOUNTS_QUERY,
    initialFilters: {
      ...(size(accountsTypeChartNumbers) > 0
        ? { accountTypeChartNumber: { in: accountsTypeChartNumbers } }
        : {}),
      companyNanoId: { eq: companyNanoId }
    },
    initialSort: [FetchAccountsSortTypes.ACCOUNT_TYPE_CHART_NUMBER_ASC],
    initialLimit: 100
  });

  const options = useMemo<MultiSelectExtendedDataType[]>(
    () =>
      accounts.map((item) => ({
        value: item.id,
        label: item.accountType?.chartNumber
      })),
    [accounts]
  );

  const handleInputChange = useCallback<MultiSelectInputChangeCallbackType>(
    (input) =>
      changeAccountsFilters(
        {
          accountTypeChartNumber: {
            ...(size(accountsTypeChartNumbers) > 0
              ? { in: accountsTypeChartNumbers }
              : {}),
            ilike: input || undefined
          }
        },
        input || size(accountsTypeChartNumbers) > 0
          ? []
          : ['accountTypeChartNumber']
      ),
    [accountsTypeChartNumbers, changeAccountsFilters]
  );

  return (
    <AccountMultiSelectField<T>
      classNamePrefix="av"
      control={control}
      data={options}
      disabled={disabled}
      emptyValue={undefined}
      error={errorMessage}
      i18nLabel={i18nLabel}
      inputWrapperClassName="p-0 mt-1"
      isLoading={accountsFetchingNextPage}
      isSearchable={false}
      labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
      menuPosition="fixed"
      multi={false}
      name={name}
      onInputChange={handleInputChange}
      onMenuScrollToBottom={loadMoreAccounts}
      optionsLoading={accountsLoading}
    />
  );
}

export default AccountSelectField;
