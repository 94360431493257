import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import some from 'lodash/some';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';

import {
  ProfileMultiSelectOptionalProps,
  ProfileMultiSelectValueType,
  ProfileMultiSelectDataType
} from './ProfileMultiSelectFieldControl.types';

import { MultiSelectChangeCallbackType } from '../../../../../../helpers/MultiSelect/types';
import {
  MultiSelect,
  MultiSelectProps,
  MultiSelectValueType
} from '../../../../../../helpers/MultiSelect';

interface ProfileMultiSelectFieldControlRequiredProps {
  value?: ProfileMultiSelectValueType;
  onChange?: (updateValue: ProfileMultiSelectValueType) => void;
}

function ProfileMultiSelectFieldControl({
  classNamePrefix = null,
  closeMenuOnSelect = true,
  data = [],
  notSelectableValues,
  defaultValue = null,
  disabled = false,
  emptyValue = null,
  i18nLabel = null,
  i18nPlaceholder,
  inputWrapperClassName = null,
  isClearable = false,
  isSearchable = false,
  labelClassName,
  menuIsOpen,
  menuPlacement = 'auto',
  menuPosition,
  multi = false,
  name,
  onInputChange,
  optionsLoading = false,
  placeholder = null,
  onMenuScrollToBottom,
  isLoading = false,
  onBlur,
  onChange,
  value,
  error,
  errorClassName,
  showError
}: MultiSelectProps &
  ProfileMultiSelectOptionalProps &
  ProfileMultiSelectFieldControlRequiredProps) {
  const handleChange = useCallback<MultiSelectChangeCallbackType>(
    (updateValue) => {
      onChange(
        isArray(updateValue) ? map(updateValue, 'value') : updateValue?.value
      );
    },
    [onChange]
  );

  const currentMultiSelectValue = useMemo<MultiSelectValueType>(
    () =>
      filter(data, (option) =>
        isArray(value)
          ? some(value, (v) => v === option.value)
          : value === option.value
      ),
    [data, value]
  );

  const filteredData = useMemo<ProfileMultiSelectDataType[]>(
    () =>
      isEmpty(notSelectableValues)
        ? data
        : filter(data, (item) => !includes(notSelectableValues, item?.value)),
    [data, notSelectableValues]
  );

  const prevMultiSelectValue = useRef<MultiSelectValueType>();

  useEffect(() => {
    prevMultiSelectValue.current = currentMultiSelectValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const multiSelectValue = useMemo<MultiSelectValueType>(
    () =>
      isEmpty(currentMultiSelectValue) && !isEmpty(value)
        ? prevMultiSelectValue.current
        : currentMultiSelectValue,
    [currentMultiSelectValue, prevMultiSelectValue, value]
  );

  return (
    <MultiSelect
      classNamePrefix={classNamePrefix}
      closeMenuOnSelect={closeMenuOnSelect}
      data={filteredData}
      defaultValue={defaultValue}
      disabled={disabled}
      emptyValue={emptyValue}
      error={error}
      i18nLabel={i18nLabel}
      i18nPlaceholder={i18nPlaceholder}
      inputWrapperClassName={inputWrapperClassName}
      isClearable={isClearable}
      isSearchable={isSearchable}
      labelClassName={labelClassName}
      menuIsOpen={menuIsOpen}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      multi={multi}
      onChange={handleChange}
      onInputChange={onInputChange}
      optionsLoading={optionsLoading}
      placeholder={placeholder}
      value={multiSelectValue}
      name={name}
      onMenuScrollToBottom={onMenuScrollToBottom}
      isLoading={isLoading}
      onBlur={onBlur}
      errorClassName={errorClassName}
      showError={showError}
    />
  );
}

export default ProfileMultiSelectFieldControl;
