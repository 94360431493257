import React, { ChangeEventHandler, useCallback } from 'react';
import cl from 'classnames';

import { IsDisabled, ClassName } from '../../types';

import { ToggleValue, ToggleOnChange } from './Toggle.types';

interface ToggleProps {
  name?: string;
  addClassName?: ClassName;
  value: ToggleValue;
  disabled?: IsDisabled;
  onChange: ToggleOnChange;
}

function Toggle({
  name,
  addClassName,
  value,
  disabled,
  onChange
}: ToggleProps) {
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(() => {
    onChange(!value);
  }, [value, onChange]);

  return (
    <label
      className={cl(
        addClassName,
        'flex items-center cursor-pointer relative rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 dark:focus:ring-gray-100 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900'
      )}
      data-role="toggle"
    >
      <input
        type="checkbox"
        name={name}
        checked={value}
        disabled={disabled}
        className="sr-only"
        onChange={handleChange}
      />
      <div className="toggle-bg bg-gray-200 dark:bg-gray-700 h-6 w-11 rounded-full" />
    </label>
  );
}

export default Toggle;
