import { gql } from 'graphql-request';

export const CHECK_IN_TO_SMART_CONTRACT_RULE_QUERY = gql`
  mutation CheckInToSmartContractRule(
    $uuid: ID!
    $contractorCompetencyId: ID!
  ) {
    checkInToSmartContractRule(
      input: { uuid: $uuid, contractorCompetencyId: $contractorCompetencyId }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
