import {
  TeamEmail,
  TeamImageFile,
  TeamName,
  TeamNanoID,
  TeamUUID
} from '../../../../teams/teamsTypes';
import {
  InvoiceInvoiceBillingInfoContactEmail,
  InvoiceInvoiceBillingInfoNanoID
} from '../../../invoicesTypes';

export interface PayInternalInvoiceFormData {
  accountId?: string;
}

export interface PayInternalInvoiceFormInvoiceBillingInfoType {
  nanoId: InvoiceInvoiceBillingInfoNanoID;
  contactEmail: InvoiceInvoiceBillingInfoContactEmail;
}

export interface PayInternalInvoiceFormTeamType {
  uuid: TeamUUID;
  nanoId: TeamNanoID;
  email: TeamEmail;
  name: TeamName;
  image: {
    file: TeamImageFile;
  } | null;
}

export enum PayInternalInvoiceFields {
  ACCOUNT_ID = 'accountId'
}
