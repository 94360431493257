import cl from 'classnames';
import { Control } from 'react-hook-form';

import { ClassName } from '../../../../../types';
import {
  PayInternalInvoiceFields,
  PayInternalInvoiceFormData
} from './PayInternalInvoiceForm.types';

import { Form } from '../../../../../helpers/Form';
import { AccountSelectField } from '../../../../accounts/helpers/AccountSelectField';
import {
  internalAccountsTypeChartNumbers,
  avCompanyNanoId
} from '../../../../accounts/accountsConstants';

interface PayInternalInvoiceFormProps {
  form: string;
  innerWrapperClassName?: ClassName;
  control: Control<PayInternalInvoiceFormData>;
}

function PayInternalInvoiceForm({
  form,
  innerWrapperClassName,
  control
}: PayInternalInvoiceFormProps) {
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <div className={cl(innerWrapperClassName || 'p-4')}>
        <AccountSelectField<PayInternalInvoiceFormData>
          control={control}
          name={PayInternalInvoiceFields.ACCOUNT_ID}
          companyNanoId={avCompanyNanoId}
          accountsTypeChartNumbers={internalAccountsTypeChartNumbers}
        />
      </div>
    </Form>
  );
}

export default PayInternalInvoiceForm;
