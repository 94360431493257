import React from 'react';

import {
  ErrorMessage,
  FormID,
  IsLoading,
  RegisterFormFieldType
} from '../../../../../types';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { Form } from '../../../../../helpers/Form';
import { InputField } from '../../../../../helpers/FormFields/InputField';

import { formsFields } from '../../../../../locales/keys';
import { TextAreaField } from '../../../../../helpers/FormFields/TextAreaField';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { TasksPermissions } from '../../../tasksConstants';

interface RevisionTaskFormProps {
  form: FormID;
  isLoading: IsLoading;
  registerImplementationDate: RegisterFormFieldType<HTMLInputElement>;
  registerFeedback: RegisterFormFieldType<HTMLTextAreaElement>;
  implementationDateValidationError: ErrorMessage;
}

function RevisionTaskForm({
  form,
  isLoading,
  registerImplementationDate,
  registerFeedback,
  implementationDateValidationError
}: RevisionTaskFormProps) {
  const currentUser = useCurrentUser();
  return (
    <Form className="flex-1 overflow-y-auto px-2" id={form}>
      <CheckPermissions
        action={TasksPermissions.READ_TASK_REVISION_IMPLEMENTATION_DATE_FILED}
      >
        <div className="p-4">
          <div className="flex flex-col gap-4">
            <div className="flex gap-3 flex-wrap">
              <div className="w-full sm:w-auto sm:flex-1">
                <InputField
                  disabled={
                    isLoading ||
                    !currentUser.hasPermissions(
                      TasksPermissions.CHANGE_TASK_REVISION_IMPLEMENTATION_DATE_FILED
                    )
                  }
                  error={implementationDateValidationError}
                  i18nLabel={formsFields.implementationDate}
                  inputWrapperClassName="relative mt-1"
                  labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                  name={registerImplementationDate.name}
                  onChange={registerImplementationDate.onChange}
                  ref={registerImplementationDate.ref}
                  showErrorIcon={false}
                  type="datetime-local"
                />
              </div>
            </div>
          </div>
        </div>
      </CheckPermissions>
      <div className="p-4">
        <div className="flex flex-col gap-4">
          <div className="flex gap-3 flex-wrap">
            <div className="w-full sm:w-auto sm:flex-1">
              <TextAreaField
                disabled={isLoading}
                i18nLabel={formsFields.description}
                labelClassName="block text-sm font-medium text-gray-700 dark:text-gray-300"
                textAreaWrapperClassName="mt-1"
                name={registerFeedback.name}
                onChange={registerFeedback.onChange}
                ref={registerFeedback.ref}
                rows={5}
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default RevisionTaskForm;
