import {
  CheckInSmartContractShareGqlQuery,
  CheckInSmartContractShareGqlStatus,
  SmartContractShareID,
  SmartContractShareUUID,
  SmartContractShareGqlError,
  UpdateSmartContractSharesCacheKeys
} from '../../smartContractSharesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface CheckInSmartContractShareOptions {
  query: CheckInSmartContractShareGqlQuery;
  cacheKeys?: UpdateSmartContractSharesCacheKeys;
}

interface CheckInSmartContractShareRecordResponse {
  id: SmartContractShareID;
}

export interface CheckInSmartContractShareResponse {
  checkInSmartContractShare: {
    status: CheckInSmartContractShareGqlStatus;
    recordUuid: SmartContractShareUUID;
    record: CheckInSmartContractShareRecordResponse;
    errors: CheckInSmartContractShareError;
  };
}

export interface CheckInSmartContractShareInput {
  uuid: SmartContractShareID;
}

export interface CheckInSmartContractShareError {
  fullMessages: SmartContractShareGqlError;
}

const action = 'checkInToSmartContractShare';

function useCheckInSmartContractShare({
  query,
  cacheKeys
}: CheckInSmartContractShareOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryErrorMessage,
    updateQueryLoading
  } = useUpdateQuery<
    CheckInSmartContractShareInput,
    CheckInSmartContractShareResponse,
    CheckInSmartContractShareError,
    CheckInSmartContractShareRecordResponse
  >({ action, cacheKeys, query });

  return {
    checkInSmartContractShareData: updateQueryData,
    checkInSmartContractShareError: updateQueryError,
    checkInSmartContractShareLoading: updateQueryLoading,
    checkInSmartContractShareErrorMessage: updateQueryErrorMessage,
    checkInSmartContractShare: updateQuery,
    checkInSmartContractShareReset: updateQueryReset
  };
}

export default useCheckInSmartContractShare;
