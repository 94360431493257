import { gql } from 'graphql-request';
import {
  UserCompetencyCompetencyID,
  UserCompetencyCompetencyName,
  UserCompetencyDefaultAt,
  UserCompetencyID,
  UserCompetencyLabel,
  UserCompetencyNanoID,
  UserCompetencyPresenceType,
  UserCompetencyUUID,
  UserCompetencyValue
} from '../userCompetenciesTypes';

interface UserCompetencyCompetency {
  id: UserCompetencyCompetencyID;
  name: UserCompetencyCompetencyName;
}

export interface FetchUserCompetenciesQueryResponse {
  id: UserCompetencyID;
  uuid: UserCompetencyUUID;
  nanoId: UserCompetencyNanoID;
  presenceType: UserCompetencyPresenceType;
  value: UserCompetencyValue;
  label: UserCompetencyLabel;
  defaultAt: UserCompetencyDefaultAt;
  competency: UserCompetencyCompetency;
}

export const FETCH_USER_COMPETENCIES_QUERY = gql`
  query FetchUserCompetencies(
    $filters: UserCompetenciesFilters
    $sort: [UserCompetenciesSortEnum!]
    $limit: Int
    $offset: Int
  ) {
    userCompetencies(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      nodes {
        id
        uuid
        nanoId
        presenceType
        value
        label
        defaultAt
        competency {
          id
          name
        }
      }
      paginationInfo {
        nextPage
        totalCount
      }
    }
  }
`;
