import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';
import toNumber from 'lodash/toNumber';
import map from 'lodash/map';

import { SmartContractID } from '../../../../../../smartContracts/smartContractsTypes';
import { UserClient, UserID } from '../../../../../../users/usersTypes';
import { GeneralLedgerID } from '../../../../../../generalLedgers/generalLedgersTypes';

import {
  SmartContractShareIteration,
  SmartContractShareShareType,
  SmartContractShareShareTypes,
  SmartContractShareStatus,
  UpdateSmartContractSharesCacheKeys
} from '../../../../../smartContractSharesTypes';
import {
  CreateSmartContractShareFormData,
  CreateSmartContractShareFormShareCreatedStatus,
  CreateSmartContractShareFormView
} from '../../CreateSmartContractShareForm.types';

import { CREATE_SMART_CONTRACT_SHARE_QUERY } from '../../../../../queries/createSmartContractShare.query';

import { useReactHookForm } from '../../../../../../common/hooks/base/useReactHookForm';
import { useCreateSmartContractShare } from '../../../../../hooks/useCreateSmartContractShare';

interface CreateSmartContractShareFormOptions {
  iteration?: SmartContractShareIteration;
  smartContractId: SmartContractID;
  status: SmartContractShareStatus;
  cacheKeys?: UpdateSmartContractSharesCacheKeys;
  defaultUsers?: {
    id: UserID;
    client?: UserClient;
    generalLedgerId?: GeneralLedgerID;
    shareType?: SmartContractShareShareType;
    share?: number;
    createdStatus?: CreateSmartContractShareFormShareCreatedStatus;
  }[];
  afterCreate?: () => void;
}

type CheckCreatedUser = { success: UserID[]; failed: UserID[] };

const emptyUserIds: CheckCreatedUser = { success: [], failed: [] };

const createSmartContractShareFormDefaultValues: CreateSmartContractShareFormData =
  {
    users: {
      clients: [],
      workers: []
    },
    iteration: null
  };

function useCreateSmartContractShareForm({
  iteration,
  smartContractId,
  status,
  cacheKeys,
  defaultUsers,
  afterCreate
}: CreateSmartContractShareFormOptions) {
  const checkCreatedUser = useRef<CheckCreatedUser>(emptyUserIds);

  const isEmptyDefaultUsers = isEmpty(defaultUsers);
  const defaultValues = useMemo(
    () =>
      isEmptyDefaultUsers
        ? { ...createSmartContractShareFormDefaultValues, iteration }
        : {
            users: {
              clients: map(
                defaultUsers.filter((user) => user.client),
                (user) => ({
                  userId: user.id,
                  share: user.share || 0,
                  shareType: user.shareType,
                  generalLedgerId: user.generalLedgerId
                })
              ),
              workers: map(
                defaultUsers.filter((user) => !user.client),
                (user) => ({
                  userId: user.id,
                  share: user.share || 0,
                  shareType: user.shareType,
                  generalLedgerId: user.generalLedgerId
                })
              )
            },
            iteration
          },
    [defaultUsers, isEmptyDefaultUsers, iteration]
  );

  const [formView, setFormView] = useState(
    isEmptyDefaultUsers
      ? CreateSmartContractShareFormView.SELECT_USERS
      : CreateSmartContractShareFormView.CHANGE_SHARE
  );

  const [disabledSubmit, setDisabledSubmit] = useState(isEmptyDefaultUsers);

  const {
    handleSubmitReactHookForm,
    control,
    resetForm,
    watch,
    setValue,
    getValues
  } = useReactHookForm<CreateSmartContractShareFormData>({
    defaultValues,
    isModalForm: true
  });

  const {
    createSmartContractShare,
    createSmartContractShareLoading,
    createSmartContractShareErrorMessage,
    createSmartContractShareReset
  } = useCreateSmartContractShare({
    query: CREATE_SMART_CONTRACT_SHARE_QUERY,
    cacheKeys
  });

  const handleResetCreateSmartContractShareForm = useCallback<
    () => void
  >(() => {
    checkCreatedUser.current.failed = [];
    checkCreatedUser.current.success = [];
    resetForm(defaultValues);
    setFormView(
      isEmptyDefaultUsers
        ? CreateSmartContractShareFormView.SELECT_USERS
        : CreateSmartContractShareFormView.CHANGE_SHARE
    );
    setDisabledSubmit(isEmptyDefaultUsers);
    createSmartContractShareReset();
  }, [
    createSmartContractShareReset,
    defaultValues,
    isEmptyDefaultUsers,
    resetForm
  ]);

  const goToChangeShareView = useCallback(async () => {
    setFormView(CreateSmartContractShareFormView.CHANGE_SHARE);
  }, []);

  const goToSelectUsersView = useCallback(async () => {
    setFormView(CreateSmartContractShareFormView.SELECT_USERS);
  }, []);

  useEffect(() => {
    const subscription = watch((data, { type }) => {
      if (formView === CreateSmartContractShareFormView.SELECT_USERS) {
        const emptyData =
          isEmpty(data.users.clients) && isEmpty(data.users.workers);

        disabledSubmit !== emptyData && setDisabledSubmit(emptyData);
      }

      if (createSmartContractShareErrorMessage && type === 'change') {
        createSmartContractShareReset();
      }
    });
    return () => subscription.unsubscribe();
  }, [
    createSmartContractShareErrorMessage,
    createSmartContractShareReset,
    disabledSubmit,
    formView,
    watch
  ]);

  return {
    control,
    createSmartContractShare,
    createSmartContractShareLoading,
    createSmartContractShareErrorMessage,
    createSmartContractShareReset,
    handleResetCreateSmartContractShareForm,
    handleCreateSmartContractShare: handleSubmitReactHookForm({
      onSubmit: async (data: CreateSmartContractShareFormData) => {
        const allUsers = concat(data.users.clients, data.users.workers);

        const usersForCreating = isEmpty(checkCreatedUser.current.success)
          ? allUsers
          : allUsers.filter(
              (user) => !checkCreatedUser.current.success.includes(user.userId)
            );

        const creatingShares = usersForCreating.map((user) =>
          createSmartContractShare({
            generalLedgerId: user.generalLedgerId,
            iteration: toNumber(data.iteration) || 0,
            finalAt: !data.iteration ? new Date().toISOString() : undefined,
            shareType: user.shareType as SmartContractShareShareTypes,
            share: toNumber(user.share),
            smartContractId: smartContractId,
            status
          })
            .then(
              (result) => {
                const createdUserId =
                  result?.createSmartContractShare?.record?.id;

                if (createdUserId) {
                  checkCreatedUser.current.success = [
                    ...checkCreatedUser.current.success,
                    user.userId
                  ];
                }

                return result;
              },
              (error) => {
                checkCreatedUser.current.failed = [
                  ...checkCreatedUser.current.failed,
                  user.userId
                ];

                throw error;
              }
            )
            .finally(() => {
              const workers = data.users.workers;
              const clients = data.users.clients;

              const workersWithCreatedStatus = map(workers, (worker) => ({
                ...worker,
                createdStatus: checkCreatedUser.current.success.includes(
                  worker.userId
                )
                  ? CreateSmartContractShareFormShareCreatedStatus.SUCCESS
                  : CreateSmartContractShareFormShareCreatedStatus.FAILED
              }));

              const clientsWithCreatedStatus = map(clients, (client) => ({
                ...client,
                createdStatus: checkCreatedUser.current.success.includes(
                  client.userId
                )
                  ? CreateSmartContractShareFormShareCreatedStatus.SUCCESS
                  : CreateSmartContractShareFormShareCreatedStatus.FAILED
              }));

              if (!isEmpty(workersWithCreatedStatus)) {
                setValue('users.workers', workersWithCreatedStatus);
              }

              if (!isEmpty(clientsWithCreatedStatus)) {
                setValue('users.clients', clientsWithCreatedStatus);
              }
            })
        );

        return Promise.all(creatingShares).then(() => {
          afterCreate?.();
        });
      },
      dirtyFieldsOnly: false
    }),
    setValue,
    disabledSubmit,
    formView,
    goToChangeShareView,
    goToSelectUsersView: isEmptyDefaultUsers ? goToSelectUsersView : undefined,
    oneFormView: !isEmptyDefaultUsers,
    getValues
  };
}

export default useCreateSmartContractShareForm;
