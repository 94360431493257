import { gql } from 'graphql-request';

import {
  UserClient,
  UserCurrentTeamID,
  UserCurrentTeamName,
  UserCurrentTeamNanoID,
  UserCurrentTeamPreferredPaymentMethod,
  UserCurrentTeamTerms,
  UserCurrentTeamType,
  UserEmail,
  UserFullName,
  UserID,
  UserNanoID,
  UserUUID
} from '../usersTypes';

export interface FetchUserTeamsQueryResponse {
  id: UserID;
  uuid: UserUUID;
  nanoId: UserNanoID;
  email: UserEmail;
  fullName: UserFullName;
  client: UserClient;
  currentTeam: {
    id: UserCurrentTeamID;
    name: UserCurrentTeamName;
    nanoId: UserCurrentTeamNanoID;
    preferredPaymentMethod: UserCurrentTeamPreferredPaymentMethod;
    terms: UserCurrentTeamTerms;
    type: UserCurrentTeamType;
  };
}

export const FETCH_USER_TEAMS_QUERY = gql`
  query UserTeams($uuid: ID!) {
    user(uuid: $uuid) {
      id
      uuid
      nanoId
      email
      client
      fullName
      currentTeam {
        id
        name
        nanoId
        preferredPaymentMethod
        terms
        type
      }
    }
  }
`;
