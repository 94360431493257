import React from 'react';
import { Controller } from 'react-hook-form';

import {
  MultiSelectFieldControlProps,
  MultiSelectFieldProps
} from './ProfileMultiSelectField.types';

import {
  ProfileMultiSelectFieldControl,
  ProfileMultiSelectOptionalProps,
  ProfileMultiSelectValueType
} from './components/ProfileMultiSelectFieldControl';

function ProfileMultiSelectField<T>({
  classNamePrefix = null,
  closeMenuOnSelect = true,
  control,
  data = [],
  notSelectableValues,
  defaultValue = null,
  disabled = false,
  emptyValue = null,
  i18nLabel = null,
  i18nPlaceholder,
  inputWrapperClassName = null,
  isClearable = false,
  isSearchable = false,
  labelClassName,
  menuIsOpen,
  menuPlacement = 'auto',
  menuPosition,
  multi = false,
  name,
  onInputChange,
  optionsLoading = false,
  placeholder = null,
  onMenuScrollToBottom,
  onChange,
  isLoading = false,
  onBlur,
  error
}: MultiSelectFieldControlProps<T> &
  MultiSelectFieldProps<T> &
  ProfileMultiSelectOptionalProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange: onMultiSelectChange, value, name },
        fieldState: { error: fieldError }
      }) => (
        <ProfileMultiSelectFieldControl
          classNamePrefix={classNamePrefix}
          closeMenuOnSelect={closeMenuOnSelect}
          data={data}
          notSelectableValues={notSelectableValues}
          defaultValue={defaultValue}
          disabled={disabled}
          emptyValue={emptyValue}
          error={fieldError?.message || error}
          i18nLabel={i18nLabel}
          i18nPlaceholder={i18nPlaceholder}
          inputWrapperClassName={inputWrapperClassName}
          isClearable={isClearable}
          isSearchable={isSearchable}
          labelClassName={labelClassName}
          menuIsOpen={menuIsOpen}
          menuPlacement={menuPlacement}
          menuPosition={menuPosition}
          multi={multi}
          onChange={(newValue) => {
            onMultiSelectChange(newValue);
            onChange?.(newValue);
          }}
          onInputChange={onInputChange}
          optionsLoading={optionsLoading}
          placeholder={placeholder}
          value={value as ProfileMultiSelectValueType}
          name={name}
          onMenuScrollToBottom={onMenuScrollToBottom}
          isLoading={isLoading}
          onBlur={onBlur}
        />
      )}
    />
  );
}

export default ProfileMultiSelectField;
