import React, { useMemo } from 'react';

import { UserNanoID } from '../../../../../../users/usersTypes';

import {
  FETCH_USER_TEAMS_QUERY,
  FetchUserTeamsQueryResponse
} from '../../../../../../users/queries/fetchUserTeams.query';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useUser } from '../../../../../../users/hooks/useUser';

import { Icon } from '../../../../../../../helpers/Icon';
import { Translate } from '../../../../../../../helpers/Translate';
import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { TeamPath } from '../../../../../../teams/TeamPath';
import { UserCache } from '../../../../../../users/UserCache';

import { tasksKeys } from '../../../../../../../locales/keys';

interface SmartContractCheckInModalAlertMessageProps {
  userNanoId: UserNanoID;
}

function SmartContractCheckInModalAlertMessage({
  userNanoId
}: SmartContractCheckInModalAlertMessageProps) {
  const currentUser = useCurrentUser();

  const selfProfile = userNanoId === currentUser.nanoId;

  const { user } = useUser<FetchUserTeamsQueryResponse>({
    cacheKey: UserCache.showUserTeamsCacheKey(currentUser.nanoId),
    query: FETCH_USER_TEAMS_QUERY,
    uuid: userNanoId,
    options: {
      enabled: !selfProfile,
      enabledPlaceholder: !selfProfile
    }
  });

  const profileHref = useMemo<string>(() => {
    if (selfProfile) {
      return TeamPath.currentCompanyProfileSmartContractSettings();
    }

    if (user) {
      return TeamPath.companyProfileSmartContractSettings(
        user.currentTeam?.nanoId
      );
    }

    return '';
  }, [selfProfile, user]);

  return (
    <div>
      <div className="flex gap-2 p-2 rounded-md bg-yellow-300/30">
        <Icon
          icon={IconsEnum.EXCLAMATION_SOLID}
          className="h-4 w-4 shrink-0 text-yellow-500 dark:text-yellow-400"
        />

        <div className="text-2xs">
          <Translate id={tasksKeys.cannotCheckInTheSmartContractWithNoShares} />

          <br />
          {profileHref && (
            <NextPureLinkHelper
              className="underline"
              href={profileHref}
              i18nText={tasksKeys.goToProfileSettings}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SmartContractCheckInModalAlertMessage;
