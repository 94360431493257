import React from 'react';

import { ClassName, AfterSubmitFunc } from '../../../../../types';

import { IconsEnum } from '../../../../../assets/icons/types';

import { TaskNanoID } from '../../../tasksTypes';

import { useRevisionTaskForm } from '../../forms/RevisionTaskForm/hooks/useRevisionTaskForm';

import { RevisionTaskForm } from '../../forms/RevisionTaskForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { FormModalButton } from '../../../../../helpers/buttons/FormModalButton';

import { tasksKeys, words } from '../../../../../locales/keys';

interface RevisionTaskModalButtonProps {
  taskNanoId: TaskNanoID;
  className?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  afterSubmit?: AfterSubmitFunc;
}

const REVISION_TASK_FORM = 'revision-task-form';

function RevisionTaskModalButton({
  taskNanoId,
  className,
  icon,
  iconClassName,
  afterSubmit
}: RevisionTaskModalButtonProps) {
  const {
    resetForm,
    handleRevisionTask,
    revisionTaskErrorMessage,
    revisionTaskLoading,
    revisionTaskReset,
    registerImplementationDate,
    registerFeedback,
    validationErrors
  } = useRevisionTaskForm({
    taskNanoId
  });

  return (
    <FormModalButton
      i18nText={tasksKeys.buttons.sendToRevision}
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      icon={icon}
      iconClassName={iconClassName}
      form={REVISION_TASK_FORM}
      i18nSubmitText={words.submit}
      i18nTitle={tasksKeys.buttons.sendToRevision}
      isLoading={revisionTaskLoading}
      onOpen={resetForm}
      onClose={revisionTaskReset}
      onSubmit={handleRevisionTask}
      afterSubmit={afterSubmit}
    >
      <RevisionTaskForm
        form={REVISION_TASK_FORM}
        isLoading={revisionTaskLoading}
        registerImplementationDate={registerImplementationDate}
        registerFeedback={registerFeedback}
        implementationDateValidationError={
          validationErrors.implementationDateValidationError
        }
      />

      <div className="px-4">
        <AlertMessage message={revisionTaskErrorMessage} />
      </div>
    </FormModalButton>
  );
}

export default RevisionTaskModalButton;
